import React from "react"
import Layout from "components/layout"
import usePageData from 'hooks/queryFAQPage';
import ViewFAQ from 'components/ViewFAQ'

const FAQPage = props => {
  const { pageContext } = props;
  const pageData = usePageData();

  return (
    <Layout pageContext={pageContext}>
      <ViewFAQ pageData={pageData} />
    </Layout>
  )
}

export default FAQPage
